import axios from 'axios';

const sendPostRequest = (endpoint, data, productionOnly) => {
    const canMakeRequest = !productionOnly || process.env.REACT_APP_ENVIRONMENT == "production";
    console.log({canMakeRequest});
    if(canMakeRequest) {
        return axios
        .post(endpoint, data, {headers: { 'x-api-key': process.env.REACT_APP_BACKEND_API_KEY }})
        .then(res => {
            if(!!res && !!res.data) {
                return res.data;
            } else {
                return res;
            }
        })
        .catch((err) => {
            console.log("err sendPostRequest");
            console.log(err);
            return err;
        });
    }
}

export default sendPostRequest;
