import React, { useState } from 'react'
import aspenRenewables from '../images/aspen-renewables.png';
import sendPostRequest from '../services/requests';
import { normalizePhoneInput } from '../services/phone';

export default function PersonalInfoPage(props) {
    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        appointmentDateTime: '',
        consentToContact: false,
        isLoading: false
    }
    const [state, setState] = useState(initialState);
   
    const handleNext = (e) => {
        e.preventDefault();
        setState({
            ...state,
            isLoading: true
        })
        const leadData = {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            phoneNumber: state.phoneNumber
        }
        const endpoint = process.env.REACT_APP_API_URL + "/api/leads";
        sendPostRequest(endpoint, {...leadData }).then(res => {
            setState({
                ...state,
                isLoading: false,
                message: "Thank you!  We look forward to meeting with you."
            })
        })

    }
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }
    
    const getIsPhoneValid = (phone) => {
        if(!!phone) {
            if(phone.length === 14) {
                return true;
            }
        }
    }
    const handleChangePhoneNumber = e => {
        setState(prevState => {
            const normalizedInput = normalizePhoneInput(e.target.value, prevState.phoneNumber);
            const isValidPhone = getIsPhoneValid(normalizedInput);
            return {
                ...state,
                phoneNumber: normalizedInput,
                isValidPhone
            }
        });
    }

    const handleChangeCheckbox = (e) => {
        setState({
            ...state,
            [e.target.name]: !state[e.target.name]
        })
    }

    const openInNewWindow = (e, route) => {
        e.preventDefault();
        window.open(route);
    }
   
    const {
        isLoading,
        message,
        firstName,
        lastName,
        email,
        phoneNumber,
        appointmentDateTime,
        consentToContact
    } = state;
    if(isLoading) {
        return (
            <>
            <div className="d-flex align-items-center justify-content-center top ">
                <img  src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
            </div>
            <div className="container cntnr removeBackground">
                <div className='formContact'>
                    <h3>LOADING</h3>
                </div>
            </div>
            </>
        )
    } else if (message) {
        return (
            <>
            <div className="d-flex align-items-center justify-content-center top ">
                <img  src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
            </div>
            <div className="container cntnr removeBackground">
                <div className='formContact'>
                    <h3>{message}</h3>
                </div>
            </div>
            </>
        )
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-center top ">
                <img  src={aspenRenewables} alt="Aspen Renewables" className="hover-pointer" />
            </div>
            <div className="container cntnr removeBackground">
              
                <form className="formContact" onSubmit={handleNext}> 
                    <div className="text-center ">
                        <h5 className="goodNews" > <b className="goodNews"> Good News! </b> </h5>
                        <p className='primeCandidate'>Your house is a prime candidate to go solar with new Solar Stimulus program! </p>
                    </div> 
                    <label className="labelContact contactLetters" htmlFor="firstName">First Name</label>
                    <input 
                        className="inputContact"
                        type="text"
                        aria-label="First Name"
                        name="firstName"
                        id="firstName"
                        value={firstName}
                        onChange={handleChange}
                        required
                    />
                    <label className="labelContact contactLetters" htmlFor="lastName">Last Name</label>
                    <input
                        className="inputContact"
                        type="text"
                        aria-label="Last Name"
                        name="lastName"
                        id="lastName"
                        value={lastName}
                        onChange={handleChange}
                        required
                    />
                    <label className="labelContact contactLetters" htmlFor="email">Email</label>
                    <input 
                        className="inputContact"
                        type="email"
                        aria-label="Email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    <label className="labelContact contactLetters" htmlFor="phoneNumber">Phone</label>
                    <input 
                        className="inputContact"
                        type="phone"
                        aria-label="Phone"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChangePhoneNumber}
                        required
                    />
                    {/* <label className="labelContact contactLetters" htmlFor="appointmentDateTime">Appointment Date and Time</label> */}
                    {/* <input 
                        className="inputContact"
                        type="datetime-local"
                        aria-label="Appointment Date and Time"
                        name="appointmentDateTime"
                        id="appointmentDateTime"
                        value={appointmentDateTime}
                        onChange={handleChange}
                        required
                    /> */}
                    <div style={{ display: 'flex', width:'100%', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <input 
                            style={{ flexBasis:'20%', marginRight: '20px', marginBottom: '0px', height:'40px' }}
                            className="inputContact"
                            type="checkbox"
                            aria-label="I consent to be contacted"
                            name="consentToContact"
                            id="consentToContact"
                            value={consentToContact}
                            onChange={handleChangeCheckbox}
                            required
                        />
                        <label
                            style={{flexBasis:'80%', display: 'flex', 'alignItems':'center', 'marginBottom': '0px'}}
                            className="labelContact contactLetters"
                            htmlFor="consentToContact"
                        >I authorize marketing messages via text from Aspen Renewables</label>
                    </div>
                    <button className="btnLndng btn btn-primary" type="submit" disabled={!firstName || !lastName || !email || !phoneNumber || !consentToContact}>Submit</button>
                    <p className='disclaimer'>By submitting this request, you are agreeing to our <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/terms-of-use')}> Terms and Conditions</a>  and <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/privacy-policy')}> Privacy Policy </a>, and authorize us and/or our partners to email, call, leave recorded messages and/or send recurring texts (msg & data rates may apply) about our services even if your number is on any federal, state, or local do not call list. Your consent to this agreement is not required to purchase products or services. We respect your privacy. To manage your contact preferences, please refer to our <a href='#' onClick={e => openInNewWindow(e,'https://www.aspenrenewables.us/privacy-policy')}> Privacy Policy</a>.</p>
                    </form>
            </div>
        </>
    )
}
